<template>
    <div>
        <div class="add_box">
            <div v-if="!isSecond" class="add_first">
                <div class="feed_form">
                    <div class="feed_left">
                        <div class="feed_question">原手机号码</div>
                        <div class="feed_question">验证码</div>
                    </div>
                    <div class="feed_right">
                        <div class="phnum">{{userData.phoneNumber}}</div>
                        <el-input class="code-box" v-model="code" auto-complete="new-password"
                                            placeholder="请输入验证码"></el-input>
                        <span class="getcode" :class="disabled?'disable':''" @click="send('before',userData.phoneNumber)">{{text}}</span>
                        <span class="cderror" v-if="isSend">已成功发送验证码至您的手机</span>

                    </div>
                </div>
                <el-button class="search_btn" type="primary" size="small" @click="next">下一步</el-button>
            </div>
            <div v-if="isSecond" class="add_first">
                <div class="feed_form">
                   <div class="feed_left">
                        <div class="feed_question">新手机号码</div>
                        <div class="feed_question">验证码</div>
                    </div>
                    <div class="feed_right">
                        <el-input v-model="phoneNum" auto-complete="new-password"
                                            placeholder="请输入您需要绑定的手机号码"></el-input>
                        <span class="pnerror" v-if="isNum">手机号码格式不正确</span>
                        <el-input class="code-box" v-model="code" auto-complete="new-password"
                                            placeholder="请输入验证码"></el-input>
                        <span class="getcode" :class="disabled?'disable':''" @click="send('modify',phoneNum)">{{text}}</span>
                        <span class="cderror" v-if="isSend">已成功发送验证码至您的手机</span>

                    </div>
                </div>
                <el-button class="search_btn" type="primary" size="small" @click="commit">确认</el-button>
            </div>
        </div>
    </div>

</template>

<script>
import {getStore,setStore,getLocalStore} from "@/util/store";
let Base64 = require('js-base64').Base64;
export default {
    props: {
        userData:{}
    },
    data() {
        return {
            phoneNum:'',
            code:'',
            isNum:false,
            isSend:false,
            time:0,
            disabled:false,
            phoneCodeAnsw:'',
            isSecond:false,
            oldPwd:'',
            newPwd:'',
            againPwd:'',
            isOld:false,
            isNew:false,
        }
    },
    created(){
        
    },
    mounted() {
        console.log(this.userData)
    },
    computed: {
        text: function () {
            return this.time > 0 ? this.time + 's 后重发送' : '获取验证码';
        }
    },
    methods:{
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'industryAnalysis',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        send(type,num){
            var myreg=/^[1][3,4,5,7,8,9][0-9]{9}$/;
            if(num=="" || !myreg.test(num)){
                this.isNum = true;
                return false;
            }else{
                this.axios
                .request({
                    loading: true,
                    method: "post",
                    url: "/dms/personalInfo/getVerificationCode",
                    params: {
                        phoneNumber: num,
                        codeUse: type,
                    },
                })
                .then((res) => {
                    console.log(res)
                    if(res.data.status=='success'){
                        this.isSend = true;
                        this.phoneCodeAnsw = Base64.decode(res.data.data.code);
                        this.disabled = true;
                        this.time = 60;
                        this.timer();
                    }else{
                        this.$message.error(res.data.msg);
                    }
                    
                });
            }
            
        },
        timer: function () {
            if (this.time > 0) {
                this.time--;
                setTimeout(this.timer, 1000);
            }else{
            	this.disabled = false;
            }
        },
        //绑定手机号并回到父页面
        next(){
            if(this.code!=''&&this.phoneCodeAnsw==this.code){
                this.code = '';
                this.time = 0;
                this.disabled = false;
                this.isSend = false;
                this.isSecond = true;
            }else{
                this.$message.error('验证码输入有误');
            }
        },
        commit(){
            if(this.code!=''&&this.phoneCodeAnsw==this.code){
                this.axios
                    .request({
                        loading: true,
                        method: "post",
                        url: "/dms/personalInfo/modifyPhone",
                        data: {
                            userId: getLocalStore("userinfo").id,
                            userSource:  getLocalStore("userinfo").userSource,
                            phoneNumber: this.phoneNum,
                        },
                    })
                    .then((res) => {
                        if(res.data.status=='success'){
                            this.$message.success('修改成功');
                            this.isSecond = false;
                            this.isSend = false;
                            this.code = '';
                            this.time = 0;
                            this.disabled = false;
                            this.$emit("listenSuccess");
                        }else{
                            this.$message.error(res.data.msg);
                        }
                        
                    });
            }else{
                this.$message.error('验证码输入有误');
            }
        },
        
  
      
    }
}
</script>

<style scoped  lang="less">
.delete{
    color: #D40000 !important;
}
.add_box{
    width: 100%;
    padding-bottom: 20px;
    .add_title{
        font-size: 16px;
        font-weight: 500;
        color: #555555;
        line-height: 22px;
    }
    .add_first{
        text-align: center;
    }
    .feed_form{
        width: 330px;
        margin: 0 auto;
        overflow: hidden;
        padding-top: 50px;
        box-sizing: border-box;
        .feed_left{
            width: 90px;
            float: left;
            text-align: right;
            .feed_question{
                height: 32px;
                line-height: 32px;
                margin-bottom: 24px;
                >i{
                    color: #D40000;
                }
            }
            .feed_proposal{
                padding-top: 6px;
                height: 74.4px;
                box-sizing: border-box;
                margin-bottom: 24px;
            }
        }
        .feed_right{
            width: 224px;
            float: right;
            text-align: left;
            position: relative;
            .phnum{
                height: 32px;
                line-height: 32px;
                margin-bottom: 24px;
            }
            .value{
                cursor: pointer;
                height: 32px;
                line-height: 32px;
                margin-bottom: 24px;
            }
            .pnerror{
                position: absolute;
                font-size: 12px;
                color: #FF3D23;
                left: 0;
                top: 34px;
            }
            .agerror{
                position: absolute;
                font-size: 12px;
                color: #FF3D23;
                left: 0;
                top: 148px;
            }
            .tips{
                position: absolute;
                font-size: 12px;
                color: #333;
                left: 0;
                top: 92px;
            }
            .cderror{
                position: absolute;
                font-size: 12px;
                color: #999999;
                left: 0;
                top: 91px;
            }
        }
        .el-input{
            height: 32px;
            margin-bottom: 24px;
            /deep/.el-input__icon{
                line-height: 32px !important;
            }
        }
        /deep/.el-input__inner{
            width: 214px;
            height: 32px !important;
            line-height: 32px !important;
            padding: 0 10px;

        }
        .code-box{
            width: 108px;
            /deep/.el-input__inner{
                width: 108px;
            }
        }
        .getcode{
            float: right;
            height: 32px;
            line-height: 32px;
            color: #185BCE;
            cursor: pointer;
        }
        .disable{
            color: #999;
            cursor: initial;
        }
    }
    .search_btn{
        margin: 87px auto 0;
    }
    .left{
        left: 530px;
    }
    .right{
        left: 610px;
    }
}

</style>