<template>
  <div class="base-container">
    <div class="main">
      <div class="tag">个人信息<el-button class="backbtn" type="text" @click="back()">返回上级</el-button></div>
      <div class="info_box">
        <div class="header">
          <span class="title">个人信息</span>
          <img v-if="!userData.phoneNumber" src="../../assets/img/info.png" alt="">
          <span v-if="!userData.phoneNumber" class="desc">您当前的账户安全系数较低，请补充手机号码</span>
        </div>
        <div class="item_box">
          <div class="name">用户名</div>
          <div class="item_content">
            <span>{{userData.userName}}</span>
          </div>
        </div>
        <div class="item_box">
          <div class="name">手机号码</div>
          <!-- 有手机号 -->
          <div v-if="userData.phoneNumber" class="item_content">
            <span>{{userData.privacyPhoneNumber}}</span>
          </div>
          <div v-if="userData.phoneNumber" class="item_edit">
            <div class="edit_btn" @click="bindPhone('change')">
              <i class="el-icon-edit"></i>
              <span>修改</span>
            </div>
          </div>
          <!-- 无手机号 -->
          <div v-if="!userData.phoneNumber" class="item_edit">
            <img src="../../assets/img/info.png" alt="">
            <span>未绑定手机号码</span>
            <div class="edit_btn" @click="bindPhone('bind')">
              <i class="el-icon-edit"></i>
              <span>绑定</span>
            </div>
          </div>
        </div>
        <div class="item_box">
          <div class="name">用户密码</div>
          <!-- <div class="item_content">
            <span>YLZ1030234</span>
          </div> -->
          <div class="item_edit">
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <div class="edit_btn" :class="!userData.phoneNumber?'disable':''" @click="changePwd">
              <i class="el-icon-edit"></i>
              <span>修改</span>
            </div>
          </div>
        </div>
        <div class="item_box">
          <div class="name">区域信息</div>
          <div v-if="isChoseCity" class="item_content">
            <span>{{userData.province || ' '}}-{{userData.city || ' '}}-{{userData.county || ' '}}</span>
          </div>
          <div v-if="!isChoseCity">
            <el-cascader
              size="large"
              :options="areaData"
              v-model="selectedOptions"
              @change="areaHandleChange"
              
            >
            </el-cascader>
            <el-button type="primary" size="small" @click="cityAffirm()">确定</el-button>
            <el-button size="small" @click="cancel('isChoseCity')">取消</el-button>
          </div>
          
          <div class="item_edit" v-if="isChoseCity">
            <div class="edit_btn" @click="choseCity">
              <i class="el-icon-edit"></i>
              <span>修改</span>
            </div>
          </div>
        </div>
       
        <div class="item_box">
          <div class="name">所属行业</div>
          <div v-if="userData.userIndustry&&!isEditDictIndustry" class="item_content">
            <span>{{userData.userIndustry}}</span>
          </div>
          <div v-if="userData.userIndustry&&!isEditDictIndustry" class="item_edit">
            <div class="edit_btn" @click="choseDictIndustry">
              <i class="el-icon-edit"></i>
              <span>修改</span>
            </div>
          </div>
          <div v-if="isEditDictIndustry">
            <el-select v-model="dictIndustry" placeholder="请选择">
                <el-option
                    v-for="item in dictIndustryData"
                    :key="item.industryCode"
                    :label="item.industryName"
                    :value="item.industryCode"
                >
                </el-option>
            </el-select>
            <el-button type="primary" size="small" @click="dictIndustryAffirm()">确定</el-button>
            <el-button size="small" @click="cancel('isEditDictIndustry')">取消</el-button>
          </div>
          <div class="item_edit" v-if="isDictIndustry">
            <img src="../../assets/img/info.png" alt="">
            <span>未选择所属行业</span>
            <div class="edit_btn" @click="choseDictIndustry">
              <i class="el-icon-edit"></i>
              <span>选择</span>
            </div>
          </div>
        </div>
        <div class="item_box">
          <div class="name">职能</div>
          <div v-if="userData.userJob&&!isEditWork" class="item_content">
            <span>{{userData.userJob}}</span>
          </div>
          <div v-if="userData.userJob&&!isEditWork" class="item_edit">
            <div class="edit_btn" @click="choseWork">
              <i class="el-icon-edit"></i>
              <span>修改</span>
            </div>
          </div>
          <div v-if="isEditWork">
            <el-input v-model="reg_work" auto-complete="new-password"
                                            placeholder="请输入您的职能"></el-input>
            <el-button type="primary" size="small" @click="workAffirm()">确定</el-button>
            <el-button size="small" @click="cancel('isEditWork')">取消</el-button>
          </div>
          
          <div class="item_edit" v-if="isWork">
            <img src="../../assets/img/info.png" alt="">
            <span>未选择职能</span>
            <div class="edit_btn" @click="choseWork">
              <i class="el-icon-edit"></i>
              <span>修改</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="phoneTxt" :visible.sync="showBindPhone" @close="closeBindPhone" width="740px">
        <bindPhone v-on:listenSuccess="againGet"></bindPhone>
    </el-dialog>
    <el-dialog :title="phoneTxt" :visible.sync="showChangePhone" @close="closeBindPhone" width="740px">
        <changePhone v-on:listenSuccess="againGet" :userData="userData"></changePhone>
    </el-dialog>
    <el-dialog title="修改用户密码" :visible.sync="showChangePwd" @close="closeBindPhone" width="740px">
        <changePwd v-on:listenSuccess="againGet" :userData="userData"></changePwd>
    </el-dialog>
  </div>
</template>

<script>
import { getStore, getLocalStore  } from "@/util/store";
import { mapMutations } from "vuex";
import { regionData, CodeToText, TextToCode } from "element-china-area-data"; // 地址级联选择器
import bindPhone from '@/views/personalDetails/components/bindPhone.vue'
import changePhone from '@/views/personalDetails/components/changePhone.vue'
import changePwd from '@/views/personalDetails/components/changePwd.vue'

export default {
  components: {
      bindPhone:bindPhone,
      changePhone:changePhone,
      changePwd:changePwd,
    
  },
  data() {
    return {
      reg_work:'',
      isWork:true,
      isEditWork:false,
      isDictIndustry:true,
      isEditDictIndustry:false,
      dictIndustryData:[],
      dictIndustry:'',
      showBindPhone:false,
      showChangePhone:false,
      isChoseCity:true,
      selectedOptions:[],
      areaData: [],
      userData:{},
      phoneTxt:'绑定手机号',
      showChangePwd:false
    };
  },
  created() {
    //对省市区数据进行修改
    this.areaData = regionData;
    if(this.areaData[0].label=='全部'){
      this.areaData.splice(0,1)
    }
    this.areaData.forEach((item,i) => {
      if(item.children[0].label=='全部'){
         item.children.splice(0,1)
      }
    })
    this.getPersonalInfo();
    this.getDictIndustry();
  },
  destroyed() {},
  mounted() {},
  methods: {
    ...mapMutations(["reducePath", "addMenuList", "reduceMenuList"]),
    back() {
        // this.reduceMenuList();//删除历史菜单中最后一个元素（当前页的路由）
        var $path = getStore("pdfrom");
        this.$router.push({path: $path});
    },
    getPersonalInfo(){
      this.axios
        .request({
          loading: true,
          method: "post",
          url: "/dms/personalInfo/getPersonalInfo",
          params: {
            userId: getLocalStore("userinfo").id,
            userSource:  getLocalStore("userinfo").userSource,
          },
        })
        .then((res) => {
          console.log(res)
          this.userData = res.data.data;
          if(this.userData.userIndustry){
            this.isDictIndustry = false
          }
          if(this.userData.userJob){
            this.isWork = false
          }
        });
    },
    choseWork(){
      this.isWork = false;
      this.isEditWork = true;
    },
    workAffirm(){
      if(this.reg_work!=''&&this.reg_work.length<21){
        this.axios
        .request({
          loading: true,
          method: "post",
          url: "/dms/personalInfo/modifyJob",
          data: {
            userId: getLocalStore("userinfo").id,
            userSource:  getLocalStore("userinfo").userSource,
            userJob:this.reg_work
          },
        })
        .then((res) => {
          console.log(res)
          if(res.data.status=='success'){
            this.userData.userJob = res.data.data.userJob;
            this.isEditWork = false;
            this.$message.success('修改成功');
          }else{
            this.$message.error(res.data.msg);
          }
        });
      }else{
        if(this.reg_work==''){
          this.$message.error('请先输入职能');
        }else{
          this.$message.error('职能请控制在20字以内');
        }
        
      }
    },
    choseDictIndustry(){
      this.isDictIndustry = false;
      this.isEditDictIndustry = true;
    },
    dictIndustryAffirm(){
      if(this.dictIndustry!=''){
        this.axios
        .request({
          loading: true,
          method: "post",
          url: "/dms/personalInfo/modifyIndustry",
          data: {
            userId: getLocalStore("userinfo").id,
            userSource:  getLocalStore("userinfo").userSource,
            userIndustry:this.dictIndustry
          },
        })
        .then((res) => {
          if(res.data.status=='success'){
            this.userData.userIndustry = res.data.data.industryName;
            this.isEditDictIndustry = false;
            this.$message.success('修改成功');
          }else{
            this.$message.error(res.data.msg);
          }
          //调接口后
          // this.isDictIndustry = true;
         
        });
      }else{
        this.$message.error('请先选择行业');
      }
      
      
    },
    //绑定手机号
    bindPhone(type){
      if(type=='bind'){
        this.phoneTxt = '绑定手机号'
        this.showBindPhone = true;
      }else{
        this.phoneTxt = '修改手机号'
        this.showChangePhone = true
      }
    },
    //关闭绑定手机窗口
    closeBindPhone(){
      this.showBindPhone = false;
    },
    //城市选择
    areaHandleChange(){

    },
    choseCity(){
      this.isChoseCity = false;
    },
    cityAffirm(){
      if(this.selectedOptions.length!=0){
        this.axios
        .request({
          loading: true,
          method: "post",
          url: "/dms/personalInfo/modifyArea",
          data: {
            userId: getLocalStore("userinfo").id,
            userSource:  getLocalStore("userinfo").userSource,
            provinceCode:this.selectedOptions[0],
            cityCode:this.selectedOptions[1],
            countyCode:this.selectedOptions[2],
          },
        })
        .then((res) => {
          if(res.data.status=='success'){
            this.userData.province = res.data.data.province;
            this.userData.city = res.data.data.city;
            this.userData.county = res.data.data.county;
            this.$message.success('区域信息修改成功!');
            this.isChoseCity = true;
          }else{
            this.$message.error(res.data.msg);
          }
          

        });
      }else{
        this.$message.error('请先选择区域信息');
      }
      
    },
    //获取行业数据
    getDictIndustry(){
        this.axios.request({
            method: "get", 
            url: '/dms/getDictIndustry',
        }).then((res) => {
            this.dictIndustryData = res.data.data;
        })
    },
    //重新获取个人信息
    againGet(){
      this.getPersonalInfo();
      this.showBindPhone = false;
      this.showChangePhone = false
      this.showChangePwd = false;
    },
    //修改密码
    changePwd(){
      if(!this.userData.phoneNumber){
        this.$message.error('请先绑定手机号码');
      }else{
        this.showChangePwd = true;
      }
    },
    cancel(type){
      if(type=='isEditDictIndustry'){
        if(!this.userData.userIndustry){
          this.isDictIndustry = true;
        }
        this.isEditDictIndustry = false;
      }else if(type=='isEditWork'){
        if(!this.userData.userJob){
          this.isWork = true;
        }
        this.isEditWork = false;
      }else if(type=='isChoseCity'){
        this.isChoseCity = true;
      }
    }


    
  },
};
</script>

<style scoped lang="less">
::-webkit-scrollbar {
  width: 0;
}
.main{
  width: 800px;
  margin: 0 auto;
  position: relative;
}
.tag {
  color: #969799;
  margin: 16px 0;
  position: relative;
}
.backbtn{
  position: absolute;
  right: 0; 
  top: -10px;
}
.info_box{
  width: 800px;
  height: 593px;
  background: #fff;
  .header{
    width: 100%;
    height: 77px;
    border-bottom: 1px solid #D8D8D8;
    line-height: 76px;
    padding-left: 24px;
    box-sizing: border-box;
    margin-bottom: 16px;
    .title{
      font-size: 20px;
      font-weight: 600;
      color: #333333;
    }
    .desc{
      font-size: 12px;
      color: #999999;
    }
    img{
      margin-left: 25px;
      margin-right: 9px;
    }
  }
  .item_box{
    width: 500px;
    height: 68px;
    margin: 0 auto;
    line-height: 68px;
    border-bottom: 1px solid #D8D8D8;
    .name{
      width: 82px;
      height: 68px;
      text-align: right;
      line-height: 68px;
      font-size: 14px;
      font-weight: 600;
      color: #333333;
      float: left;
      margin-right: 60px;
    }
    .item_content{
      float: left;
      height: 68px;
      line-height: 68px;
      color: #333333;
    }
    .item_edit{
      img{
        float: left;
        margin-top: 26px;
        margin-right: 9px;
      }
      span{
        color: #999999;
      }
      .dot{
        float: left;
        width: 4px;
        height: 4px;
        background: #333333;
        margin-right: 4px;
        border-radius: 4px;
        margin-top: 32px;
      }
      .edit_btn{
        float: right;
        font-size: 14px;
        color: #185BCE;
        cursor: pointer;
        span{
          color: #185BCE;
          margin-left: 8px;
        }
      }
      .disable{
        color: #999999 !important;
        span{
          color: #999999;
        }
      }
    }
    
  }
  .el-select{
    width: 214px;
    margin-right: 16px;
  }
  .el-input{
    width: 214px;
    height: 32px;
    line-height: 32px;
    float: left;
    margin-top: 19px;
    margin-right: 16px;
  }
  .el-cascader{
    width: 214px;
    margin-right: 16px;
  }
  /deep/.el-input__inner{
    height: 32px;
    line-height: 32px;
  }
}
/deep/.el-dialog__header{
  padding-bottom: 20px !important;
}
/deep/.el-dialog__body{
  border-top: 1px solid #DCDEE0;
}

</style>